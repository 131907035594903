import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { HeroDayPicker } from '~/components/HeroDayPicker/HeroDayPicker'
import { selectGetOccupancies, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getToday } from '~/utils/extendedDayjs'

type Props = {
    selectedMonth: Dayjs
    selectedDay?: Dayjs
    setSelectedDay: (day: Dayjs) => void
    onMonthChange: (day: Dayjs) => void
    isLoading: boolean
    surgery: UnScheduledSurgery
}

export const AvailableDaysPicker = ({ selectedMonth, selectedDay, setSelectedDay, onMonthChange, isLoading, surgery }: Props) => {
    const today = getToday()
    const surgeryTypeId = surgery?.bookingId ? (surgery.surgeryType?.id ?? 0) : 0

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const occupancies = useStore(selectGetOccupancies)

    const occupancyData = useMemo(() => {
        return isLoading ? {} : occupancies.byMonth(selectedMonth.startOf('month'), departmentKey, true, surgeryTypeId)
    }, [isLoading, occupancies, selectedMonth, departmentKey, surgeryTypeId])

    const isDateDisabled = (date: Date) => {
        const dateOcc = occupancyData[dayjs(date).toISOString()]
        // undefined signifies that the day has no availability and should be disabled
        if (!dateOcc) return true
        return dateOcc.length === 0
    }

    return (
        <>
            <HeroDayPicker
                required
                disabled={[{ before: today.toDate() }, isDateDisabled]}
                onMonthChange={date => onMonthChange(dayjs(date))}
                weekdaysOnly={true}
                month={selectedMonth.toDate()}
                // cant book in the past
                startMonth={today.toDate()}
                selected={selectedDay?.toDate()}
                onSelect={date => setSelectedDay(dayjs(date))}
                mode="single"
            />
        </>
    )
}
