import { DipsEntityKey } from '../slices/dipsSlice'
import { DiEntityKey } from '../store'
import { relation } from '../utils/denormalize'

// Main schema for entity relations
// Would be great if we could generate this from the SQLAlchemy models
export const schema = {
    departments: {
        locations: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'departmentLocationAssignments',
            throughLocalKey: 'department_id',
            throughForeignKey: 'location_id',
            fromEntity: 'locations',
            foreignKey: 'id',
        }),
        practitioners: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'departmentPractitionerAssignments',
            throughLocalKey: 'department_id',
            throughForeignKey: 'practitioner_id',
            fromEntity: 'practitioners',
            foreignKey: 'id',
        }),
        sections: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'sections',
            foreignKey: 'department_id',
        }),
    },
    departmentPractitionerAssignments: {
        department: relation({
            type: 'hasOne',
            localKey: 'department_id',
            fromEntity: 'departments',
            foreignKey: 'id',
        }),
        practitioner: relation({
            type: 'hasOne',
            localKey: 'practitioner_id',
            fromEntity: 'practitioners',
            foreignKey: 'id',
        }),
    },
    locations: {
        departments: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'departmentLocationAssignments',
            throughLocalKey: 'location_id',
            throughForeignKey: 'department_id',
            fromEntity: 'departments',
            foreignKey: 'id',
        }),
        schedules: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'locationSchedules',
            foreignKey: 'location_id',
        }),
        dipsResource: relation({
            type: 'hasOne',
            localKey: 'room_code',
            fromEntity: 'dipsResources',
            foreignKey: 'shortName',
        }),
    },
    practitioners: {
        departments: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'departmentPractitionerAssignments',
            throughLocalKey: 'practitioner_id',
            throughForeignKey: 'department_id',
            fromEntity: 'departments',
            foreignKey: 'id',
        }),
        departmentAssignments: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'departmentPractitionerAssignments',
            foreignKey: 'practitioner_id',
        }),
        schedules: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'practitionerSchedules',
            foreignKey: 'practitioner_id',
        }),
    },
    practitionerSchedules: {
        practitioner: relation({
            type: 'hasOne',
            localKey: 'practitioner_id',
            fromEntity: 'practitioners',
            foreignKey: 'id',
        }),
        locationAssignments: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'practitionerScheduleLocations',
            foreignKey: 'practitioner_schedule_id',
        }),
        locations: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'practitionerScheduleLocations',
            throughLocalKey: 'practitioner_schedule_id',
            throughForeignKey: 'location_id',
            fromEntity: 'locations',
            foreignKey: 'id',
        }),
        statuses: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'practitionerScheduleStatuses',
            foreignKey: 'practitioner_schedule_id',
        }),
    },
    practitionerScheduleLocations: {
        location: relation({
            type: 'hasOne',
            localKey: 'location_id',
            fromEntity: 'locations',
            foreignKey: 'id',
        }),
        schedule: relation({
            type: 'hasOne',
            localKey: 'practitioner_schedule_id',
            fromEntity: 'practitionerSchedules',
            foreignKey: 'id',
        }),
    },
    practitionerScheduleStatuses: {
        schedule: relation({
            type: 'hasOne',
            localKey: 'practitioner_schedule_id',
            fromEntity: 'practitionerSchedules',
            foreignKey: 'id',
        }),
    },
    blockSchedules: {
        location: relation({
            type: 'hasOne',
            localKey: 'location_id',
            fromEntity: 'locations',
            foreignKey: 'id',
        }),
    },
    departmentLocationAssignments: {},
    locationSchedules: {},
    ruleDefinitions: {},
    sections: {
        department: relation({
            type: 'hasOne',
            localKey: 'department_id',
            fromEntity: 'departments',
            foreignKey: 'id',
        }),
        speciality: relation({
            type: 'hasOne',
            localKey: 'speciality_id',
            fromEntity: 'specialities',
            foreignKey: 'id',
        }),
    },
    scheduledSurgeries: {
        department: relation({
            type: 'hasOne',
            localKey: ['contact', 'department', 'id'],
            fromEntity: 'departments',
            foreignKey: 'dips_id',
        }),
        surgeryMetadata: relation({
            type: 'hasOne',
            localKey: 'id',
            fromEntity: 'surgeryMetadata',
            foreignKey: 'booking_id',
        }),
        section: relation({
            type: 'hasOne',
            localKey: ['contact', 'section', 'id'],
            fromEntity: 'sections',
            foreignKey: 'dips_section_id',
        }),
    },
    unScheduledSurgeries: {
        department: relation({
            type: 'hasOne',
            localKey: ['contact', 'department', 'id'],
            fromEntity: 'departments',
            foreignKey: 'dips_id',
        }),
        surgeryMetadata: relation({
            type: 'hasOne',
            localKey: 'bookingId',
            fromEntity: 'surgeryMetadata',
            foreignKey: 'booking_id',
        }),
        section: relation({
            type: 'hasOne',
            localKey: ['contact', 'section', 'id'],
            fromEntity: 'sections',
            foreignKey: 'dips_section_id',
        }),
    },
    blockLocks: {},
    surgeryMetadata: {},
    specialities: {
        sections: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'sections',
            foreignKey: 'speciality_id',
        }),
    },
    ageGroups: {},
    hospitalSurgeryTypes: {},
    hospitalSurgeryTypeGroupAssociations: {},
    surgeryTypeGroupAgeRestrictions: {},
    surgeryTypeGroupSpecialities: {},
    surgeryTypeGroupHierarchies: {},
    surgeryTypeGroups: {
        ageRestrictions: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'surgeryTypeGroupAgeRestrictions',
            throughLocalKey: 'surgery_type_group_id',
            throughForeignKey: 'age_group_id',
            fromEntity: 'ageGroups',
            foreignKey: 'id',
        }),
        specialities: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'surgeryTypeGroupSpecialities',
            throughLocalKey: 'surgery_type_group_id',
            throughForeignKey: 'speciality_id',
            fromEntity: 'specialities',
            foreignKey: 'id',
        }),
        children: relation({
            type: 'hasManyThrough',
            localKey: 'id',
            throughEntity: 'surgeryTypeGroupHierarchies',
            throughLocalKey: 'parent_surgery_type_group_id',
            throughForeignKey: 'child_surgery_type_group_id',
            fromEntity: 'surgeryTypeGroups',
            foreignKey: 'id',
        }),
        surgeryTypes: relation({
            type: 'hasMany',
            localKey: 'id',
            fromEntity: 'hospitalSurgeryTypeGroupAssociations',
            foreignKey: 'surgery_type_group_id',
        }),
    },
    dipsSurgeryTypes: {},
    dipsResources: {},
    dipsDepartments: {},
} satisfies Record<DiEntityKey | DipsEntityKey, unknown>
