import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { FilterDropdown, PractitionerFilter } from '~/components'
import { selectWaitingListFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { AsaFilter } from './AsaFilter'
import { DayFilter } from './DayFilter'
import { DiagnosisGroupFilter } from './DiagnosisGroupFilter'
import { ShortNoticeFilter } from './ShortNoticeFilter'
import { SurgeryCategoryFilter } from './SurgeryCategoryFilter'

export const Filters = () => {
    const { removeSingleFilter, emptyFilters } = useStore(state => state.appFilters.actions)
    const filters = useStore(selectWaitingListFilters)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)
    const installFiltersFromURL = useStore(state => state.appFilters.actions.installFiltersFromURL)

    const searchUrl = useLocation().search
    useEffect(() => installFiltersFromURL(searchUrl), [installFiltersFromURL, searchUrl])

    const handleClearFilters = () => {
        emptyFilters()
        setSelectedPage(1)
    }

    const handleOnDisplayItemRemove = (id: string) => {
        removeSingleFilter(id)
        setSelectedPage(1)
    }

    return (
        <FilterDropdown clearFilters={handleClearFilters} onDisplayItemRemove={handleOnDisplayItemRemove} displayItems={filters}>
            <ShortNoticeFilter />
            <AsaFilter />
            <PractitionerFilter />
            <DiagnosisGroupFilter />
            <SurgeryCategoryFilter />
            <DayFilter />
        </FilterDropdown>
    )
}
