import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'

import { ScheduledSurgery, selectGetScheduledSurgeries, selectGetUnScheduledSurgeries, selectSurgeryTypeGroup, UnScheduledSurgery } from '~/store/selectors'
import {
    isDayOvernightKey,
    selectASAValues,
    selectDayOvernightValues,
    selectDiagnosisGroupValues,
    selectPractitionerValues,
    selectShortNoticeValues,
    selectSurgeryCategoryValues,
} from '~/store/slices/filterSlice'
import { StoreState } from '~/store/store'
import { getSurgeons } from '~/utils/dips'
import { needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { columns, FormattedWaitingListItem, WaitingListColumn, WaitingListItem } from '../shared/columns'
import { getSurgeryCategoryName, nullishDiagnosisGroupName } from '../shared/utils'

// Select waiting list items by department only (used for filter options)
export const selectAllWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, getUnScheduledSurgeries, getScheduledSurgeries) => {
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)

// Select waiting list items by department and view
const selectWaitingListItems = createSelector(
    (state: StoreState) => state.appFilters.departmentKey,
    (state: StoreState) => state.app.activeViews.WAITING_LIST,
    selectGetUnScheduledSurgeries,
    selectGetScheduledSurgeries,
    (departmentKey, activeView, getUnScheduledSurgeries, getScheduledSurgeries) => {
        if (activeView === '/waiting-list/scheduled') {
            return getScheduledSurgeries.byDepartmentKey(departmentKey)
        } else if (activeView === '/waiting-list/unscheduled') {
            return getUnScheduledSurgeries.byDepartmentKey(departmentKey)
        }

        // Assumed 'All'
        return [...getScheduledSurgeries.byDepartmentKey(departmentKey), ...getUnScheduledSurgeries.byDepartmentKey(departmentKey)]
    }
)

function dayOvernight(dayOvernightValues: string[]) {
    return (item: UnScheduledSurgery | ScheduledSurgery) => {
        if (dayOvernightValues.length === 0) return true

        const nprCodeName = item.contact?.levelOfCareNpr?.nprCodeName

        // If valid value, then filter, otherwise include to not hide invalid values
        return isDayOvernightKey(nprCodeName) ? dayOvernightValues.includes(nprCodeName) : true
    }
}

// Apply filters to selectWaitingListItems
const selectFilteredWaitingListItems = createSelector(
    selectWaitingListItems,
    selectSurgeryTypeGroup,
    selectShortNoticeValues,
    selectASAValues,
    selectPractitionerValues,
    selectDiagnosisGroupValues,
    selectSurgeryCategoryValues,
    selectDayOvernightValues,
    (surgeries, getSurgeryTypeGroup, shortNoticeValues, asaValues, practitionerValues, diagnosisGroupValues, surgeryCategoryValues, dayOvernightValues) => {
        return surgeries
            .filter(item => shortNoticeValues.at(0) === undefined || item.contact?.isShortNotice)
            .filter(item => asaValues.length === 0 || asaValues.includes(item.surgeryOrderDetails?.asa ?? ''))
            .filter(
                item => practitionerValues.length === 0 || getSurgeons(item.surgeryResources).some(surgeon => practitionerValues.includes(surgeon.short_name))
            )
            .filter(item => diagnosisGroupValues.length === 0 || diagnosisGroupValues.includes(item.contact?.diagnosisGroupName ?? nullishDiagnosisGroupName))
            .filter(item => surgeryCategoryValues.length === 0 || surgeryCategoryValues.includes(getSurgeryCategoryName(item, getSurgeryTypeGroup) ?? ''))
            .filter(dayOvernight(dayOvernightValues))
    }
)

function transform(item: WaitingListItem) {
    const result: Record<string, unknown> = {}
    for (const [column, definitions] of Object.entries(columns)) {
        result[column] = {
            comparable: definitions.getComparable(item),
            formatted: definitions.format(item),
        }
    }

    return result as {
        [K in keyof typeof columns]: {
            formatted: ReturnType<(typeof columns)[K]['format']>
            comparable: ReturnType<(typeof columns)[K]['getComparable']>
        }
    }
}

export type TransformedWaitingListItem = ReturnType<typeof transform>

// Transform selectFilteredWaitingListItems
const selectTransformedWaitingListItems = createSelector(selectFilteredWaitingListItems, filteredItems => {
    return filteredItems.map(transform)
})

function containsNeedle(needle: string) {
    const filterTokens = needleToTokens(needle)

    return (item: TransformedWaitingListItem) => {
        return filterTokens.every(token =>
            Object.values(item).some(value =>
                isArray(value.formatted)
                    ? value.formatted.some(formatted => formatted.toLocaleLowerCase().indexOf(token) !== -1)
                    : value.formatted.toLocaleLowerCase().indexOf(token) !== -1
            )
        )
    }
}

// Apply search to selectTransformedWaitingListItems
const selectSearchedWaitingListItems = createSelector(
    selectTransformedWaitingListItems,
    (state: StoreState) => state.waitingList.pageRawNeedle,
    (filteredItems, pageRawNeedle) => {
        const needle = pageRawNeedle.toLowerCase()

        return filteredItems.filter(containsNeedle(needle))
    }
)

function getFormattedOnly(item: TransformedWaitingListItem) {
    const result: Record<string, unknown> = {}

    for (const key of Object.keys(item)) {
        result[key] = item[key as WaitingListColumn].formatted
    }

    return result as FormattedWaitingListItem
}

// Apply sorting to selectSearchedWaitingListItems and map to formatted items
export const selectSortedWaitingListItems = createSelector(
    selectSearchedWaitingListItems,
    (state: StoreState) => state.waitingList.columnSortedOn,
    (state: StoreState) => state.waitingList.sortOrder,
    (filteredItems, columnSortedOn, sortOrder) => {
        return orderBy(filteredItems, item => item[columnSortedOn].comparable, sortOrder).map(getFormattedOnly)
    }
)
