import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'

import { Button, Show } from '~/components'
import { useUnScheduledSuggestions } from '~/hooks/booking/useUnScheduledSuggestions'
import { HelpOutlined } from '~/icons'
import { OccupancyData, Practitioner, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'

import { BookingDialog } from './BookingDialog/BookingDialog'
import { EmptyItem } from './EmptyItem'
import { LoadingCard } from './LoadingCard'
import { SuggestionsInfoCard } from './SuggestionsInfoCard'
import { SurgeryCard } from './SurgeryCard'

const MAX_LIST_LENGTH = 5

type Props = {
    occupancyData: OccupancyData | null
    practitioners: Practitioner[]
}

export const BookingWaitingList = ({ practitioners, occupancyData }: Props) => {
    const [showBookingDialog, setShowBookingDialog] = useState(false)
    const [selectedSurgery, setSelectedurgery] = useState<UnScheduledSurgery>()
    const settings = useStore(state => state.user.settings)
    const setHideSuggestionsInfo = useStore(state => state.user.actions.setHideSuggestionsInfo)
    const { unScheduledSuggestions, suggestionCount, isLoading } = useUnScheduledSuggestions({
        scheduledPractitioners: practitioners,
        occupancyData,
    })
    const [showSuggestionsCard, setShowSuggestionsCard] = useState(!settings.hideSuggestionsInfo)

    const handleHideAlwaysButtonClick = () => {
        setHideSuggestionsInfo(true)
        setShowSuggestionsCard(false)
    }
    const onCloseDialog = () => {
        setShowBookingDialog(false)
        setSelectedurgery(undefined)
    }

    const hasSuggestions = suggestionCount > 0

    const displayedSuggestions = suggestionCount > MAX_LIST_LENGTH ? unScheduledSuggestions.slice(0, MAX_LIST_LENGTH) : unScheduledSuggestions

    const listTitle = `${suggestionCount > MAX_LIST_LENGTH ? `Topp ${MAX_LIST_LENGTH} av ${suggestionCount}` : `${suggestionCount}`} mulige pasienter`

    return (
        <>
            <div className="flex items-center justify-between">
                <p className="flex items-center gap-1 text-base">
                    {listTitle}
                    <Show condition={hasSuggestions}>
                        <Button size="icon" color="custom" onClick={() => setShowSuggestionsCard(prev => !prev)}>
                            <HelpOutlined />
                        </Button>
                    </Show>
                </p>
            </div>
            <AnimatePresence>
                {showSuggestionsCard && hasSuggestions && (
                    <SuggestionsInfoCard
                        showButton={!settings.hideSuggestionsInfo}
                        onHideButtonClick={handleHideAlwaysButtonClick}
                        onClose={() => setShowSuggestionsCard(false)}
                    />
                )}
            </AnimatePresence>

            <Show
                condition={!isLoading}
                fallback={
                    <>
                        <LoadingCard />
                        <LoadingCard />
                    </>
                }
            >
                {hasSuggestions ? (
                    displayedSuggestions.map(surgery => (
                        <SurgeryCard
                            surgery={{ type: 'unscheduled', value: surgery }}
                            key={surgery.bookingId}
                            onClick={surg => {
                                setSelectedurgery(surg)
                                setShowBookingDialog(true)
                            }}
                        />
                    ))
                ) : (
                    <EmptyItem>
                        <p>
                            Vi fant ingen pasienter på ventelisten
                            {practitioners.length > 0 && ' til'}
                            {practitioners.length > 0 && <span className="font-semibold"> {practitioners.map(prac => prac.short_name).join(', ')}</span>} som
                            passer disse operasjonstypene.
                        </p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-waiting-list-loaded" className="hidden" />
            </Show>
            {selectedSurgery && occupancyData && (
                <BookingDialog isOpen={showBookingDialog} onCloseDialog={onCloseDialog} waitingListItem={selectedSurgery} occupancyData={occupancyData} />
            )}
        </>
    )
}
