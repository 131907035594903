import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

import { DISelect, Popover, Tag } from '~/components'
import { PractitionerSchedule, selectGetPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { day } from '~/utils/extendedDayjs'

import { useOnCallScheduleActions } from './useOnCallScheduleActions'

type Props = {
    date: Dayjs
    onCallSchedules: PractitionerSchedule[]
}

function sortSchedulesByOnCall(schedules: PractitionerSchedule[]) {
    return schedules.toSorted((a, b) => {
        const firstOnCall = a.statuses.find(({ status_code }) => status_code === 'ON-CALL')
        const secondOnCall = b.statuses.find(({ status_code }) => status_code === 'ON-CALL')

        if (!firstOnCall || !secondOnCall) return 0

        return day(firstOnCall?.created_at).diff(day(secondOnCall?.created_at))
    })
}

export const OnCallCell = ({ date, onCallSchedules }: Props) => {
    const [open, setOpen] = useState(false)

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getPractitioners = useStore(selectGetPractitioners)

    const practitionerOptions = getPractitioners.byDepartmentKey(departmentKey).map(({ short_name, id }) => ({ label: short_name, value: id }))

    const sortedOnCallSchedules = useMemo(() => sortSchedulesByOnCall(onCallSchedules), [onCallSchedules])
    const values = useMemo(
        () => sortedOnCallSchedules.map(({ practitioner }) => practitioner && { label: practitioner.short_name, value: practitioner.id }),
        [sortedOnCallSchedules]
    )
    const { addOnCallPractitioner, removeOnCallPractitioner, deleteDateOnCall } = useOnCallScheduleActions(date)

    return (
        <Popover
            open={open}
            onOpenChange={setOpen}
            className="h-full"
            placement="right-start"
            trigger={
                <div
                    onClick={() => setOpen(prev => !prev)}
                    className="flex h-full cursor-pointer flex-nowrap items-center justify-center gap-x-2 transition-all hover:bg-gray-100"
                >
                    {sortedOnCallSchedules.at(0)?.practitioner?.short_name}
                    {sortedOnCallSchedules.length > 1 && <Tag color="on-call">+{sortedOnCallSchedules.length - 1}</Tag>}
                </div>
            }
        >
            <div className="flex max-w-[280px] select-text flex-col text-left">
                <p className="border-b px-2 py-1 text-base font-normal text-gray-400">Velg on-call</p>
                <div className="max-h-72 p-2 text-sm">
                    <DISelect
                        isMulti
                        autoFocus
                        options={practitionerOptions}
                        value={values}
                        onChange={(_, meta) => {
                            if (meta.action === 'clear') {
                                void deleteDateOnCall()
                            } else if (meta.action === 'select-option') {
                                if (!meta.option) return
                                void addOnCallPractitioner(getPractitioners.byId(meta.option.value))
                            } else if (meta.action === 'pop-value' || meta.action === 'remove-value') {
                                if (!meta.removedValue) return
                                void removeOnCallPractitioner(getPractitioners.byId(meta.removedValue.value))
                            }
                        }}
                    />
                </div>
            </div>
        </Popover>
    )
}
