import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { Show } from '~/components'
import { Location, selectGetBlockLocks, selectGetOccupancies, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'

import { BlockIcons } from '../../shared/BlockIcons'
import { shallDisplayAvailabilitiesList, shallDisplayBookingList } from '../utils'
import { BlockCard, styles } from './BlockCard'

type Props = {
    date: Dayjs
    location: Location
}

export const CellBlock = ({ date, location }: Props) => {
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const isDrawerOpen = useStore(state => state.operationalPlanner.isDrawerOpen)
    const { openDrawer } = useStore(state => state.operationalPlanner.actions)

    const getBlockLocks = useStore(selectGetBlockLocks)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const blockLock = getBlockLocks.byDateAndLocationId(date, location.id)
    const scheduledPractitioners = getScheduledPractitioners.byDateAndLocation(date, location)
    const occupancyData = getOccupancies.byDateAndLocation(date, location)

    // what is this case for?
    if (!occupancyData || !date || !location) {
        return null
    }

    const commonProps = { date, location, occupancyData }

    const isSelectedCalendarBlock = drawerProps?.location_id === location.id && drawerProps?.unixDate === date.unix()
    const isNotSelectedBookedBlock = isDrawerOpen && (!isSelectedCalendarBlock || drawerProps?.showAvailability)
    const isNotSelectedAvailableBlock = isDrawerOpen && (!isSelectedCalendarBlock || !drawerProps?.showAvailability)
    const isNotSelectedCell = isNotSelectedAvailableBlock && isNotSelectedBookedBlock

    const isBlockLocked = Boolean(blockLock)

    const hasBookingList = shallDisplayBookingList(occupancyData)
    const hasAvailabilities = shallDisplayAvailabilitiesList(occupancyData)
    const hasOnlyOneList = (hasBookingList && !hasAvailabilities) || (!hasBookingList && hasAvailabilities)
    const lockedBlockHasOnlyAvailable = isBlockLocked && !hasBookingList && hasAvailabilities
    const showAvailableBlockCard = !isBlockLocked && hasAvailabilities

    const showIconsInCell = hasBookingList && hasAvailabilities && !isBlockLocked
    const showIconsInCard = hasOnlyOneList || (hasBookingList && hasAvailabilities && isBlockLocked)

    return (
        <div className="layer-stack flex h-full flex-col gap-2">
            <Show
                condition={!lockedBlockHasOnlyAvailable}
                fallback={
                    <div className={clsx('flex h-full flex-col justify-between rounded p-2', styles['booked'], isNotSelectedBookedBlock && 'opacity-50')}>
                        <span className="mr-7 font-semibold text-blue-700">Ingenting booket</span>
                        <BlockIcons date={date} location={location} />
                    </div>
                }
            >
                <Show condition={hasBookingList}>
                    <BlockCard
                        {...commonProps}
                        className={clsx('cursor-pointer', { 'opacity-50': isNotSelectedBookedBlock })}
                        style="booked"
                        dataTest="block-booked"
                        practitioners={occupancyData.bookedPractitioners}
                        // show the list of available waiting list items if the block as availability
                        onClick={() => {
                            openDrawer({ date, location, occupancyData, hasAvailability: showAvailableBlockCard })
                            void trackEvent('OP_CARD_AVAILABILITY__CLICK')
                        }}
                        showIcons={showIconsInCard}
                    />
                </Show>
                <Show condition={showAvailableBlockCard}>
                    <BlockCard
                        {...commonProps}
                        className={clsx('cursor-pointer', { 'opacity-50': isNotSelectedAvailableBlock })}
                        style="available"
                        dataTest="block-available"
                        practitioners={scheduledPractitioners ?? []}
                        onClick={() => {
                            openDrawer({ date, location, occupancyData, hasAvailability: showAvailableBlockCard })
                            void trackEvent('OP_CARD_AVAILABILITY__CLICK')
                        }}
                        showIcons={showIconsInCard}
                    />
                </Show>
                <Show condition={showIconsInCell}>
                    <BlockIcons date={date} location={location} reduceOpacity={isNotSelectedCell} />
                </Show>
            </Show>
        </div>
    )
}
