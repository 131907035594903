import env from '~/env'
import { GetResolvedSurgeryTypeGroup } from '~/store/selectors'
import { DepartmentKey } from '~/store/slices/filterSlice'

import { WaitingListItem } from './columns'

export const waitingTimeTooltip = 'Antall dager siden\noperasjon ble\nbestilt.'

export const nullishDiagnosisGroupName = 'Uspesifisert'

export function shouldShowASAColumn(departmentId: DepartmentKey) {
    const envVar = String(env.VITE_WAITINGLIST_HIDE_ASA_FOR_DEPARTMENT_IDS).split(',')
    return !envVar.includes(String(departmentId))
}

export function getSurgeryCategoryName(surgery: WaitingListItem, getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup) {
    const surgeryTypeId = surgery.surgeryType?.id

    if (!surgeryTypeId) return

    const surgeryTypeGroups = getSurgeryTypeGroup.byHospitalId(surgeryTypeId) ?? []
    const surgeryCategories = surgeryTypeGroups.filter(surgeryTypeGroup => surgeryTypeGroup?.group_type === 'category')

    // Some dips surgery types are very generic and are used across departments/sections (i.e. "Andre operasjoner/inngrep")
    // In these cases, there are multiple surgery categories per dips surgery type and we need to find the correct one based on the section and speciality relationship
    if (surgeryCategories.length > 1) {
        const surgeryCategory = surgeryCategories.filter(surgeryCategory =>
            surgeryCategory.specialities.some(speciality => speciality.id === surgery.section?.speciality?.id)
        )

        if (surgeryCategory.length > 1) {
            console.warn('Surgery type has multiple surgery categories', surgery, surgeryCategory)
        }

        return surgeryCategory.at(0)?.display_name
    }

    return surgeryCategories.at(0)?.display_name
}
