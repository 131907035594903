import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { EmptyItem } from '~/app/pages/OperationalPlanner/BookingDrawer/EmptyItem'
import { Show } from '~/components'
import { OccupancyData, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { MinimalPractitioner } from '~/utils/dips'

import { LocationCard } from './OperatingTheaterCard'

type Props = {
    occupancies: OccupancyData[]
    showAllPractitioners: boolean
    surgeryPractitioner: MinimalPractitioner[]
    date: Dayjs
    onClick: (selectedOccupancy: OccupancyData) => void
}

export const OperatingTheaterList = ({ date, occupancies, showAllPractitioners, surgeryPractitioner, onClick }: Props) => {
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)

    const scheduledPractitioners = getScheduledPractitioners.byDateAndLocationsIds(
        date,
        occupancies.map(occ => occ.location.id)
    )

    const filterOperatingTheaterByMatchingPractitioner = useMemo(
        () =>
            occupancies.filter(occ => {
                const practitionersForLocation = scheduledPractitioners?.[occ.location.id]
                // dont show room if there are no practitioners
                if (!practitionersForLocation || practitionersForLocation?.length === 0) return false
                // check if we are to match at all
                if (showAllPractitioners || !surgeryPractitioner) return true
                return surgeryPractitioner.some(practitioner => {
                    return practitionersForLocation.map(p => p.short_name.toLocaleLowerCase()).includes(practitioner.short_name.toLocaleLowerCase())
                })
            }),
        [occupancies, scheduledPractitioners, showAllPractitioners, surgeryPractitioner]
    )

    return (
        <>
            <Show
                condition={filterOperatingTheaterByMatchingPractitioner.length > 0}
                fallback={
                    <EmptyItem>
                        <p>Vi fant ingen stuer med ledig tid på valgt dato</p>
                    </EmptyItem>
                }
            >
                {filterOperatingTheaterByMatchingPractitioner.map(occupancy => (
                    <LocationCard
                        date={date}
                        key={occupancy.location.id}
                        scheduledPractitioners={scheduledPractitioners[occupancy.location.id] ?? []}
                        occupancyData={occupancy}
                        onClick={onClick}
                    />
                ))}
            </Show>
        </>
    )
}
