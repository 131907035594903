import clsx from 'clsx'

import { PractitionerScheduleStatus } from '~/store/selectors'
import { selectStatusValues, Status, statusesMap } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'

function isStringStatus(status: string): status is Status {
    return status in statusesMap
}

type Props = {
    statuses: PractitionerScheduleStatus[]
}

export const CellScheduleStatuses = ({ statuses }: Props) => {
    const filteredStatuses = useStore(selectStatusValues)

    return (
        <>
            {statuses.map(({ status_code: status, updated_by, updated_at }) => {
                if (!isStringStatus(status)) return null

                const editInfo = `Sist endret av ${updated_by} ${humanizeDate(day(updated_at))}`
                const tooltip = `${statusesMap[status].tooltip}\n${editInfo}`

                return (
                    <div key={status} data-tooltip={tooltip}>
                        <ButtonTag
                            tabIndex={-1}
                            colorType={statusesMap[status].color}
                            fillType="all"
                            className={clsx({ 'opacity-30': filteredStatuses.length > 0 && !filteredStatuses.includes(status) })}
                        >
                            {statusesMap[status].label}
                        </ButtonTag>
                    </div>
                )
            })}
        </>
    )
}
