import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { DipsSchemas } from '~/clients/dips-client'
import { DurationBasedRule, OccupancyStatus, ResolvedPatientGroup } from '~/store/selectors'
import { accumulateDuration, getDurationInMs, subtractDuration } from '~/utils/extendedDayjs'
import { isNullish } from '~/utils/guards'

export function newDurationBasedRule(
    durationRuleType: DurationBasedRule['type'],
    patientGroupFilter: ResolvedPatientGroup | null,
    maxValue: Duration | null,
    getSurgeryOccupancy: (surgery: DipsSchemas['MinimalScheduledSurgery'], patientGroup: ResolvedPatientGroup | null) => Duration,
    marginInMinutes: number
): DurationBasedRule {
    const evaluateSurgeryDurations = function (occupancy: Duration) {
        if (isNullish(maxValue)) {
            return OccupancyStatus.Available
        }
        const occupancyValue = getDurationInMs(occupancy)

        const filledBlockWithMargin = subtractDuration(maxValue, dayjs.duration(marginInMinutes, 'minutes'))
        if (occupancyValue < getDurationInMs(filledBlockWithMargin)) {
            return OccupancyStatus.Available
        }

        const overbookedBlockWithMargin = subtractDuration(maxValue, dayjs.duration(-1 * marginInMinutes, 'minutes'))
        if (occupancyValue <= getDurationInMs(overbookedBlockWithMargin)) {
            return OccupancyStatus.Filled
        }

        return OccupancyStatus.Overbooked
    }

    return {
        type: durationRuleType,
        patientGroupFilter: patientGroupFilter,
        getSurgeryOccupancy,
        accumulate: accumulateDuration,
        subtract: subtractDuration,
        evaluate: evaluateSurgeryDurations,
        filledBlockThreshold: maxValue,
        zeroValue: dayjs.duration(0, 'minutes'),
    }
}
