import { motion } from 'framer-motion'

import { Button } from '~/components'
import { CloseOutlined } from '~/icons'

type Props = { showButton: boolean; onClose: () => void; onHideButtonClick: () => void }

export const SuggestionsInfoCard = ({ showButton, onClose, onHideButtonClick }: Props) => {
    return (
        <motion.div
            initial={{ opacity: 0, x: -24 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -24 }}
            transition={{ type: 'linear' }}
            className="rounded bg-gray-100 px-3 py-1 pb-3 shadow-md"
        >
            <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                    <p className="text-sm">Hvorfor ser jeg disse pasientene?</p>
                    <Button size="icon" onClick={onClose} color="custom">
                        <CloseOutlined width={20} />
                    </Button>
                </div>
                <p className="text-sm">
                    Pasientene nedenfor vises fordi kirurgen og operasjonstypen passer til tilgjengelig kapasitet. Pasienten øverst har stått lengst på
                    ventelisten.
                </p>
                {showButton && (
                    <Button color="transparent" size="custom" className="w-fit" onClick={onHideButtonClick}>
                        Ikke vis denne igjen
                    </Button>
                )}
            </div>
        </motion.div>
    )
}
