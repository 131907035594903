import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Show } from '~/components'
import { DipsSurgeryType, ResolvedSurgeryTypeGroup, selectEntities, selectSurgeryTypeGroup, selectSurgeryTypes } from '~/store/selectors'
import { useStore } from '~/store/store'

import { HospitalCode, SurgeryTypeGroupCode, WeightCode } from '../components/code'
import { List, ListItem } from '../components/list'

type UnimportedHospitalSurgeryTypeListItemProps = {
    code: number
    name: string
}

const UnimportedHospitalSurgeryTypeListItem = ({ code, name }: UnimportedHospitalSurgeryTypeListItemProps) => (
    <ListItem>
        <HospitalCode code={code} /> {name}
    </ListItem>
)

type OrphanHospitalSurgeryTypeListItemProps = {
    surgeryType: DipsSurgeryType
}

const OrphanHospitalSurgeryTypeListItem = ({ surgeryType }: OrphanHospitalSurgeryTypeListItemProps) => (
    <ListItem>
        <HospitalCode code={surgeryType.id} /> {surgeryType.name || '???'}
    </ListItem>
)

type HospitalSurgeryTypeListItemContentProps = {
    surgeryType: DipsSurgeryType
    surgeryTypeGroups: ResolvedSurgeryTypeGroup[]
    onClick: () => void
    isExpanded: boolean
}

const HospitalSurgeryTypeListItemContent = ({ surgeryType, surgeryTypeGroups, onClick, isExpanded }: HospitalSurgeryTypeListItemContentProps) => {
    return (
        <div
            className={clsx(
                {
                    'cursor-pointer hover:text-diBlue-600': true,
                },
                'flex flex-row items-center gap-2 border-l-2 pl-2'
            )}
            onClick={onClick}
        >
            {<div className="text-xs">{isExpanded ? '▼' : '▶'}</div>}
            <div>({Object.keys(surgeryTypeGroups).length}) </div>
            <HospitalCode code={Number(surgeryType.id)} />
            <div>{surgeryType.name}</div>
        </div>
    )
}

type HospitalSurgeryTypeListItemProps = {
    surgeryType: DipsSurgeryType
    surgeryTypeGroups: ResolvedSurgeryTypeGroup[]
}

const HospitalSurgeryTypeListItem = ({ surgeryType, surgeryTypeGroups }: HospitalSurgeryTypeListItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleContentClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded])

    return (
        <ListItem>
            <HospitalSurgeryTypeListItemContent
                surgeryType={surgeryType}
                surgeryTypeGroups={surgeryTypeGroups}
                onClick={handleContentClick}
                isExpanded={isExpanded}
            />
            <Show condition={isExpanded}>
                <List>
                    {surgeryTypeGroups.map(surgeryTypeGroup => {
                        const weight = surgeryType.id ? surgeryTypeGroup.resolvedSurgeryTypes[surgeryType.id]?.weight : null
                        return (
                            <div key={surgeryTypeGroup.id} className="flex gap-2">
                                <SurgeryTypeGroupCode code={surgeryTypeGroup?.code || ''} />
                                <WeightCode code={weight} />
                                {surgeryTypeGroup?.display_name}
                            </div>
                        )
                    })}
                </List>
            </Show>
        </ListItem>
    )
}

const HospitalSurgeryTypes = () => {
    const { orphanDipsSurgeryTypes, unimportedDipsSurgeryTypes, deprecatedHospitalSurgeryTypes } = useStore(selectSurgeryTypes)

    const getSurgeryTypeGroups = useStore(selectSurgeryTypeGroup)
    const { dipsSurgeryTypes } = useStore(selectEntities)
    const hospitalSurgeryTypesInGroups = dipsSurgeryTypes
        .map(surgeryType => {
            const surgeryTypeGroups = getSurgeryTypeGroups.byHospitalId(Number(surgeryType.id))
            if (surgeryTypeGroups) return { surgeryType, surgeryTypeGroups }
        })
        .filter(Boolean)

    return (
        <div className="flex flex-col gap-2">
            {
                <div key="unimported-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({unimportedDipsSurgeryTypes.length}) Unimported surgery types</div>
                    <List>
                        {unimportedDipsSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <UnimportedHospitalSurgeryTypeListItem code={Number(surgeryType.id)} name={surgeryType.name || '???'} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="deprecated-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({deprecatedHospitalSurgeryTypes.length}) Deprecated surgery types</div>
                    <List>
                        {deprecatedHospitalSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <UnimportedHospitalSurgeryTypeListItem
                                        code={Number(surgeryType.hospital_surgery_type_id)}
                                        name={surgeryType.hospital_surgery_type_name}
                                    />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="orphans-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({orphanDipsSurgeryTypes.length}) Orphan surgery types</div>
                    <List>
                        {orphanDipsSurgeryTypes.map(surgeryType => {
                            return (
                                <div key={surgeryType.id} className="border p-2 shadow">
                                    <OrphanHospitalSurgeryTypeListItem surgeryType={surgeryType} />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
            {
                <div key="resolved-hospital-surgery-types" className="rounded border p-2 shadow">
                    <div className="font-semibold">({hospitalSurgeryTypesInGroups.length}) Surgery types in group(s)</div>
                    <List>
                        {hospitalSurgeryTypesInGroups.map(surgeryTypeInGroups => {
                            return (
                                <div key={surgeryTypeInGroups.surgeryType.id} className="border p-2 shadow">
                                    <HospitalSurgeryTypeListItem
                                        surgeryType={surgeryTypeInGroups.surgeryType}
                                        surgeryTypeGroups={surgeryTypeInGroups.surgeryTypeGroups}
                                    />
                                </div>
                            )
                        })}
                    </List>
                </div>
            }
        </div>
    )
}

export default HospitalSurgeryTypes
