import { Link } from 'react-router-dom'

import { pages } from '~/app/routes'
import { Button } from '~/components'
import { useGetSurgeryBookingStatus } from '~/hooks/booking/useGetSurgeryBookingStatus'
import { OpenInNewOutlined } from '~/icons'
import { SurgeryForPresentation } from '~/utils/dips'

import { BookingInfo } from './BookingInfo'
import { ProcessingIndicator } from './ProcessingIndicator'

interface Props extends SurgeryForPresentation {
    onCloseDialog: () => void
    checkInTime: string
    sagaId: string
}

export const ProcessBooking = ({ bookingId, operation, patient, practitioners, location, displayDate, onCloseDialog, checkInTime, sagaId }: Props) => {
    const { status } = useGetSurgeryBookingStatus({ sagaId })

    return (
        <>
            <ProcessingIndicator status={status?.status ?? 'Ongoing'} />
            <dl className={`grid w-[585px] justify-between gap-6 rounded border p-6`}>
                <BookingInfo label="Operasjon">{operation}</BookingInfo>
                <BookingInfo label="Pasient">{patient}</BookingInfo>
                <BookingInfo label="Kirurg">
                    {practitioners
                        .filter(prac => prac.name !== undefined)
                        .map((prac, i) => (
                            <span key={prac.name}>{`${i > 0 ? ', ' : ''}${prac.short_name}`}</span>
                        ))}
                </BookingInfo>
                <BookingInfo label="Stue">{location}</BookingInfo>
                <BookingInfo label="Dato">
                    <dd className="capitalize">{displayDate}</dd>
                </BookingInfo>
                <BookingInfo label="Oppmøtetid">kl. {checkInTime}</BookingInfo>
                {status?.status === 'Completed' && (
                    <Link className="text-diBlue-500 underline" to={`${pages.WAITING_LIST.views.scheduled.path}${bookingId ? `?bookingId=${bookingId}` : ''}`}>
                        Se pasienten i Planlagt-oversikten
                    </Link>
                )}
            </dl>
            {status?.status === 'Completed' && (
                <>
                    <div className="flex gap-2" data-test="booking-success">
                        <p className="text-base font-semibold">Pasienten har fått tid til operasjon i Hero. </p>
                        <Link to="#" onClick={() => alert('LINK TO BE DETERMINED')} className="flex items-center gap-1 text-diBlue-500 underline">
                            Se pasienten i DIPS <OpenInNewOutlined height={16} width={16} />
                        </Link>
                    </div>
                    <div className="grid gap-2">
                        <p className="text-base">Dette må du gjøre i DIPS Arena</p>
                        <p>&#x2022; Send brev til pasienten</p>
                    </div>
                </>
            )}
            {status.status === 'Failed' && <div data-test="booking-failed">{status.message}</div>}

            <div className="mt-2 flex justify-end gap-2">
                <Button color="delete" onClick={onCloseDialog} data-test="done">
                    Lukk
                </Button>
            </div>
        </>
    )
}
