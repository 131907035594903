import * as Collapsible from '@radix-ui/react-collapsible'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useState } from 'react'

import { DownOutlined } from '~/icons'

const variantStyles = {
    default: 'bg-indigo-50',
    heavy: 'bg-gray-200',
    light: 'bg-gray-100',
    white: 'bg-white',
}

type Props = {
    icon?: ReactNode
    title: string | ReactNode
    className?: string
    children?: ReactNode
    defaultOpen?: boolean
    secondaryIcon?: ReactNode
    collapsedContent?: ReactNode
    variant?: keyof typeof variantStyles
}

const buttonVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
}

export const CollapsibleCard = ({ icon, title, children, defaultOpen = true, secondaryIcon, collapsedContent, variant = 'default' }: Props) => {
    const [isOpen, setIsOpen] = useState(defaultOpen)
    const headerStyles = `flex items-center gap-2  px-3 py-2 justify-between ${variantStyles[variant]} border-b`

    return (
        <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
            <div className="w-full rounded border bg-white pb-1 shadow-md">
                <Collapsible.Trigger asChild className="w-full">
                    <>
                        <div className={headerStyles}>
                            <div className="flex items-center gap-1">
                                {icon}
                                {typeof title === 'string' ? <p className="max-w-[300px] text-pretty text-left font-semibold">{title}</p> : title}
                            </div>
                            <div className="flex items-center gap-2">
                                {secondaryIcon}
                                <motion.button
                                    onClick={() => setIsOpen(prev => !prev)}
                                    variants={buttonVariants}
                                    animate={isOpen ? 'open' : 'closed'}
                                    transition={{ type: 'linear' }}
                                    whileHover={{ scale: 1.1 }}
                                >
                                    <DownOutlined />
                                </motion.button>
                            </div>
                        </div>
                        {collapsedContent && <div className="px-3 pt-4">{collapsedContent}</div>}
                    </>
                </Collapsible.Trigger>

                <AnimatePresence>
                    {isOpen && (
                        <Collapsible.Content forceMount>
                            <motion.div initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }} className="overflow-hidden px-3">
                                {children}
                            </motion.div>
                        </Collapsible.Content>
                    )}
                </AnimatePresence>
            </div>
        </Collapsible.Root>
    )
}
