import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

import { BookingDialog } from '~/app/pages/OperationalPlanner/BookingDrawer/BookingDialog/BookingDialog'
import { SurgeryCard } from '~/app/pages/OperationalPlanner/BookingDrawer/SurgeryCard'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { OccupancyData, ScheduledSurgery, selectGetScheduledSurgeries, selectGetUnScheduledSurgeries, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format, getToday } from '~/utils/extendedDayjs'

import { AvailableDaysPicker } from './AvailableDaysPicker'
import { AvailableLocations } from './AvailableLocations'

export const BookingContent = () => {
    const today = getToday()

    const [selected, setSelected] = useState<Dayjs>()
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const [month, setMonth] = useState<Dayjs>(today.startOf('month'))
    const [showAllPractitioners, setShowAllPractitioners] = useState<boolean>(false)
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [selectedOccupancy, setSelectedOccupancy] = useState<OccupancyData>()

    const monthRange = useMemo(
        () => ({
            startTime: format(month.startOf('month')),
            endTime: format(month.endOf('month')),
        }),
        [month]
    )

    // needed to derive the open slots for the selected surgery type
    const { isLoading } = useImportOccupancyEntities(monthRange.startTime, monthRange.endTime)

    const drawer = drawerProps
    const { BookingId, BookedStatus } = drawer?.item ?? {}

    const unscheduledSurgery = useStore(selectGetUnScheduledSurgeries)
    const scheduledSugery = useStore(selectGetScheduledSurgeries)

    const selectedSurgery: { type: 'unscheduled'; value: UnScheduledSurgery } | { type: 'scheduled'; value: ScheduledSurgery } | null = useMemo(() => {
        let surgery = null
        if (BookedStatus !== 'Scheduled') {
            surgery = unscheduledSurgery.byBookingId(BookingId ?? '')

            return surgery ? { type: 'unscheduled', value: surgery } : null
        }

        surgery = scheduledSugery.byBookingId(BookingId ?? '')
        return surgery ? { type: 'scheduled', value: surgery } : null
    }, [BookingId, unscheduledSurgery, scheduledSugery, BookedStatus])

    const onCloseDialog = () => {
        setSelectedOccupancy(undefined)
        setShowBookingDialog(false)
    }

    if (drawerProps === null || !selectedSurgery) return <div />

    return (
        <div className="flex flex-col gap-4" data-test="waiting-list-booking-content">
            <p className="font-bold">{BookedStatus === 'Scheduled' ? 'Planlagt' : 'Til Planlegging'}</p>
            {BookingId && <SurgeryCard hideAction surgery={selectedSurgery} />}

            {BookedStatus !== 'Scheduled' && (
                <div className="mt-4 flex flex-col gap-2">
                    <p className="font-bold">Ledige Tider</p>
                    <AvailableDaysPicker
                        selectedDay={selected}
                        setSelectedDay={setSelected}
                        surgery={selectedSurgery.value}
                        selectedMonth={month}
                        onMonthChange={setMonth}
                        isLoading={isLoading}
                    />
                    <label className="mt-2 flex w-full hover:cursor-pointer">
                        <input
                            type="checkbox"
                            className="accent-indigo-700 hover:cursor-pointer"
                            checked={showAllPractitioners}
                            onChange={e => {
                                setShowAllPractitioners(e.target.checked)
                            }}
                        />
                        <p className="ml-2">Vis ledige tider for alle kirurger</p>
                    </label>
                    <AvailableLocations
                        isLoading={isLoading}
                        selectedDay={selected}
                        selectedMonth={month}
                        showAllPractitioners={showAllPractitioners}
                        surgery={selectedSurgery.value}
                        onClick={occ => {
                            setSelectedOccupancy(occ)
                            setShowBookingDialog(true)
                        }}
                    />
                </div>
            )}
            {selectedOccupancy && (
                <BookingDialog
                    isOpen={showBookingDialog}
                    onCloseDialog={onCloseDialog}
                    waitingListItem={selectedSurgery.value}
                    occupancyData={selectedOccupancy}
                />
            )}
        </div>
    )
}
