import { Dayjs } from 'dayjs'
import { immer } from 'zustand/middleware/immer'

import { Location, OccupancyData } from '../selectors'
import { Slice } from '../store'
import { formatCapacity, formatTotalKnifeTime, formatTotalRoomDuration } from '../utils/blockEvaluation'

type DrawerProps = {
    unixDate: number
    location_id: number
    room_code: string
    showAvailability: boolean
    totalKnifeTime?: string
    totalRoomDuration?: string
    capacity?: string
}
type OpenDrawerArgs = {
    occupancyData?: OccupancyData
    date?: Dayjs
    location?: Location
    hasAvailability: boolean
}

type State = {
    isDrawerOpen: boolean
    drawerProps: DrawerProps | null
    needle: string
}

const initialState: State = {
    isDrawerOpen: false,
    drawerProps: null,
    needle: '',
}

type Actions = {
    actions: {
        set: (args: Partial<State>) => void
        openDrawer: (args: OpenDrawerArgs) => void
    }
}

export type OperationalPlannerSlice = {
    operationalPlanner: State & Actions
}

export const createOperationalPlannerSlice: Slice<OperationalPlannerSlice> = immer(set => ({
    operationalPlanner: {
        ...initialState,
        actions: {
            set: args => {
                set(state => {
                    Object.assign(state.operationalPlanner, args)
                })
            },
            openDrawer: ({ occupancyData, location, date, hasAvailability }: OpenDrawerArgs) => {
                if (!occupancyData || !location || !date) return

                // we're formatting here instead of in the BookingDrawerHeader because RuleEvaluation is unserializable
                // warning for >1 issued already in BookingsList
                const totalKnifeTime = formatTotalKnifeTime(occupancyData.bookedSurgeries)
                const totalRoomDuration = formatTotalRoomDuration(occupancyData.bookedSurgeries)
                const firstEvaluation = occupancyData.evaluations[0]
                const capacity = firstEvaluation ? formatCapacity(firstEvaluation) : undefined

                set(state => {
                    Object.assign(state.operationalPlanner, {
                        drawerProps: {
                            unixDate: date.unix(),
                            location_id: location.id,
                            room_code: location.room_code,
                            showAvailability: hasAvailability,
                            totalKnifeTime: totalKnifeTime,
                            totalRoomDuration: totalRoomDuration,
                            capacity: capacity,
                        },
                        isDrawerOpen: true,
                    })
                })
            },
        },
    },
}))
