import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import { useStore } from '~/store/store'

import { WaitingListDrawer } from './Drawer/WaitingListDrawer'
import { Header } from './Header/Header'
import { AllTable, ScheduledTable, UnscheduledTable } from './Table/ViewTables'

const WaitingListPage = () => {
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)

    // Enable mapping from surgery type to surgery category in operation type filter
    useImportSurgeryTypeGroupEntities()

    const view = {
        '/waiting-list/unscheduled': <UnscheduledTable />,
        '/waiting-list/scheduled': <ScheduledTable />,
        '/waiting-list/all': <AllTable />,
    }

    return (
        <div className="flex h-full flex-col">
            <Header />
            <div className="overflow-hidden">{view[activeView]}</div>
            <WaitingListDrawer />
        </div>
    )
}

export default WaitingListPage
