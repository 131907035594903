import { immer } from 'zustand/middleware/immer'

import { FormattedWaitingListItem, WaitingListColumn } from '~/app/pages/WaitingList/shared/columns'
import env from '~/env'

import { Slice } from '../store'

type DrawerProps = {
    item: FormattedWaitingListItem
}

type State = {
    columnSortedOn: WaitingListColumn
    sortOrder: 'desc' | 'asc'
    drawerProps: DrawerProps | null
    selectedPage: number // one-based
    itemsPerPage: number
    pageRawNeedle: string
    isFetching: boolean
    hasDeniedAccessItems: boolean
}

const initialState: State = {
    columnSortedOn: 'WaitingTime',
    sortOrder: 'desc',
    drawerProps: null,
    selectedPage: 1,
    itemsPerPage: env.VITE_WAITING_LIST_ITEMS_PER_PAGE,
    pageRawNeedle: '',
    isFetching: false,
    hasDeniedAccessItems: false,
}

type Actions = {
    actions: {
        setDrawerProps: (drawerProps: State['drawerProps']) => void
        setColumnSortedOn: (columnSortedOn: State['columnSortedOn']) => void
        toggleSortOrder: () => void
        setSelectedPage: (page: State['selectedPage']) => void
        setPageRawNeedle: (pageRawNeedle: State['pageRawNeedle']) => void
        setHasDeniedAccessItems: (value: State['hasDeniedAccessItems']) => void
    }
}

export type WaitingListSlice = {
    waitingList: State & Actions
}

export const createWaitingListSlice: Slice<WaitingListSlice> = immer(set => ({
    waitingList: {
        ...initialState,
        actions: {
            setDrawerProps(drawerProps: State['drawerProps']) {
                set(state => {
                    state.waitingList.drawerProps = drawerProps
                })
            },
            setColumnSortedOn(columnSortedOn: State['columnSortedOn']) {
                set(state => {
                    state.waitingList.columnSortedOn = columnSortedOn
                    state.waitingList.sortOrder = initialState.sortOrder
                })
            },
            toggleSortOrder: () => {
                set(state => {
                    state.waitingList.sortOrder = state.waitingList.sortOrder === 'asc' ? 'desc' : 'asc'
                })
            },
            setSelectedPage: (page: State['selectedPage']) => {
                set(state => {
                    state.waitingList.selectedPage = page
                })
            },
            setPageRawNeedle: (pageRawNeedle: State['pageRawNeedle']) => {
                set(state => {
                    state.waitingList.pageRawNeedle = pageRawNeedle
                })
            },
            setHasDeniedAccessItems: (value: State['hasDeniedAccessItems']) => {
                set(state => {
                    state.waitingList.hasDeniedAccessItems = value
                })
            },
        },
    },
}))
