import clsx from 'clsx'

import { PractitionerScheduleLocation } from '~/store/selectors'
import { selectStatusValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'

type Props = {
    locationSchedules: PractitionerScheduleLocation[]
}

export const CellScheduleLocations = ({ locationSchedules }: Props) => {
    const filteredStatuses = useStore(selectStatusValues)

    return (
        <>
            {locationSchedules.filter(Boolean).map(cellLocation => {
                const isPractitionerAndLocationSameDepartment = cellLocation.schedule?.practitioner?.departments.some(department =>
                    cellLocation.location?.departments.some(locationDepartment => department.id === locationDepartment.id)
                )

                const diffDepartmentTooltip = isPractitionerAndLocationSameDepartment ? '' : 'Kirurgen og rommet er fra forskjellige avdelinger'
                const tooltip = `Sist endret av ${cellLocation.updated_by} ${humanizeDate(day(cellLocation.updated_at))} \n ${diffDepartmentTooltip}`

                return (
                    <div key={cellLocation.location?.id} data-tooltip={tooltip}>
                        <ButtonTag
                            key={cellLocation.location?.id}
                            tabIndex={-1}
                            data-tooltip={tooltip}
                            colorType={isPractitionerAndLocationSameDepartment ? 'blue' : 'red'}
                            fillType="all"
                            className={clsx('pointer-events-none', {
                                'opacity-30': filteredStatuses.length > 0,
                                'opacity-80': !isPractitionerAndLocationSameDepartment,
                            })}
                        >
                            {cellLocation.location?.room_code}
                        </ButtonTag>
                    </div>
                )
            })}
        </>
    )
}
