import { useState } from 'react'

import { Drawer, RadioButtons } from '~/components'
import env from '~/env'
import { CloseOutlined } from '~/icons'
import { useStore } from '~/store/store'

import { BookingContent } from './Booking/BookingContent'
import { Contents } from './Contents'
import { WaitingListDrawerHeader } from './Header'

type FilterTypes = 'book' | 'patientInfo'
type FilterOption = { label: string; value: FilterTypes }

const listOptions: FilterOption[] = [
    { label: 'Om Inngrepet', value: 'book' },
    { label: 'Pasientinformasjon', value: 'patientInfo' },
]
export const WaitingListDrawer = () => {
    const { setDrawerProps } = useStore(state => state.waitingList.actions)
    const drawerProps = useStore(state => state.waitingList.drawerProps)

    const [listFilter, setListFilter] = useState<FilterTypes>(env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER ? 'book' : 'patientInfo')

    function onClose() {
        setDrawerProps(null)
    }

    return (
        <Drawer key={drawerProps?.item.BookingId} isOpen={drawerProps !== null} onClose={() => onClose()}>
            <div className="flex h-full flex-col">
                <div className="border-b px-6 py-4">
                    <div onClick={onClose} className="absolute right-3 top-2 cursor-pointer rounded-full p-2 hover:bg-slate-300">
                        <CloseOutlined />
                    </div>
                    <WaitingListDrawerHeader />
                </div>

                <div className="flex flex-col gap-4 overflow-auto px-6 py-4">
                    {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER && (
                        <RadioButtons options={listOptions} onChange={selected => setListFilter(selected)} value={listFilter} />
                    )}
                    {listFilter === 'book' && <BookingContent />}
                    {listFilter === 'patientInfo' && <Contents />}
                </div>
            </div>
        </Drawer>
    )
}
