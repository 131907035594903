import { useImportFutureScheduledEntities } from '../hooks/useImportFutureScheduledEntities'
import { useImportUnScheduledEntities } from '../hooks/useImportUnScheduledEntities'
import { Table } from './Table'

export function UnscheduledTable() {
    const { isLoading } = useImportUnScheduledEntities()

    return <Table isLoading={isLoading} />
}

export function ScheduledTable() {
    const { isLoading } = useImportFutureScheduledEntities()

    return <Table isLoading={isLoading} />
}

export function AllTable() {
    const { isLoading: isUnscheduledLoading } = useImportUnScheduledEntities()
    const { isLoading: isScheduledLoading } = useImportFutureScheduledEntities()

    return <Table isLoading={isUnscheduledLoading && isScheduledLoading} /> // The && is intentional. It allows for displaying partial data
}
