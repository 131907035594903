import * as Sentry from '@sentry/browser'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useState } from 'react'

import { getClient, isDipsUrlConfigured } from '~/clients/dips-client'
import * as hero from '~/store/di-entity.api'
import { useStore } from '~/store/store'
import { day, getToday } from '~/utils/extendedDayjs'

export function useImportCommonData() {
    const [isLoading, setIsLoading] = useState(false)
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false)
    const dipsApiAccessToken = useStore(state => state.user.dipsApiAccessToken)
    const dipsStoreActions = useStore(state => state.dips.actions)
    const isDeepinsightUser = useStore(state => state.user.issuer === 'google')

    useEffect(() => {
        const shouldWaitForDipsAccessToken = isDipsUrlConfigured() && dipsApiAccessToken === null && !isDeepinsightUser

        if (isLoading || hasLoadedOnce || shouldWaitForDipsAccessToken) {
            return
        }

        async function importCommonDipsData() {
            const dipsClient = getClient()
            const today = getToday()

            const departmentResponse = await dipsClient.GET('/api/v1/organization/departments', {
                // We're getting all of the departments that are valid/active today
                params: { query: { includeNotAccessTo: true, includeReplaced: false } },
            })

            const validDipsDepartments = (departmentResponse.data ?? [])
                .filter(dept => dept.reshId)
                .filter(dept => dept.validTo === null || day(dept.validTo).isAfter(today))
                .filter(dept => dept.validFrom === null || day(dept.validFrom).isBefore(today))

            const operatingTheaterResponses = await Promise.all(
                uniqBy(validDipsDepartments, 'reshId').map(dept => {
                    if (!dept.reshId) {
                        return
                    }

                    return dipsClient.GET('/api/v1/resources', {
                        params: {
                            query: {
                                departmentReshId: dept.reshId,
                                resourceType: 'OperatingTheater',
                            },
                        },
                    })
                })
            )

            // The uniqBy here is needed until we've fixed
            const allResources = uniqBy(operatingTheaterResponses.map(response => response?.data ?? []).flat(), 'shortName')

            dipsStoreActions.addDepartments(validDipsDepartments)
            dipsStoreActions.addResources(allResources)
        }

        async function importCommonDipsDataSafely() {
            try {
                await importCommonDipsData()
            } catch (error) {
                if (isDeepinsightUser) {
                    console.warn('Failed to import DIPS data by Google-identified user:', error)
                    return
                }
                throw error
            }
        }

        async function importCommonHeroData() {
            return await Promise.all([
                // Organization
                hero.importDepartments({ exclude_locations: true, exclude_practitioners: true, exclude_sections: true }),
                hero.importSections({}),
                hero.importLocations({ exclude_departments: true }),
                hero.importPractitioners({ exclude_departments: true }),
                hero.importDepartmentLocationAssignments({}),
                hero.importDepartmentPractitionerAssignments({}),

                hero.importRuleDefinitions({}),
                hero.importSpecialities({}),
                hero.importAgeGroups({}),
                hero.importSurgeryTypeGroupAgeRestrictions({}),
                hero.importSurgeryTypeGroupHierarchies({}),
                hero.importSurgeryTypeGroupSpecialties({}),
                hero.importSurgeryTypeGroups({}),
                hero.importHospitalSurgeryTypes({}),
                hero.importHospitalSurgeryTypeGroupAssociations({ exclude_surgery_type: true }),
            ])
        }

        async function importCommonData() {
            setIsLoading(true)

            // We're measuring the time it takes to import all of the data from DIPS,
            // since it can cause a lot of queries
            const promises: Promise<unknown>[] = [Sentry.startSpan({ name: 'importCommonHeroData' }, importCommonHeroData)]

            if (isDipsUrlConfigured()) {
                promises.push(Sentry.startSpan({ name: 'importCommonDipsData' }, importCommonDipsDataSafely))
            }

            await Promise.all(promises)

            setIsLoading(false)
            setHasLoadedOnce(true)
        }

        const timeout = setTimeout(importCommonData, 10)
        return () => clearTimeout(timeout)
    }, [dipsApiAccessToken, isLoading, hasLoadedOnce, dipsStoreActions, isDeepinsightUser])

    return { isLoading, hasLoadedOnce }
}
