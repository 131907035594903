import { Show } from '~/components'
import {
    PractitionerScheduleLocation,
    PractitionerScheduleStatus,
    selectGetLocations,
    selectGetPractitionerScheduleLocations,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
} from '~/store/selectors'
import { Status } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate, isHoliday } from '~/utils/extendedDayjs'

import { DatePractitionerCell } from '../utils'
import { LocationTagsWithStaticState } from './LocationTagsWithStaticState'
import { ModalElement } from './ModalElement'
import { PractitionerComment } from './PractitionerComment'
import { StatusTag } from './StatusTag'

const allStatuses: Status[] = ['SURGERY', 'DAY_SURGERY', 'POLICLINIC', 'EVENING_POLYCLINIC', 'ON-CALL', 'VACATION', 'OTHER']

type Props = {
    selectedCells: DatePractitionerCell[]
}

function getLatestUpdatedAtAndBy(allScheduleStatusesByCell: PractitionerScheduleStatus[], allScheduleLocationsByCell: PractitionerScheduleLocation[]) {
    return allScheduleStatusesByCell
        .map(({ updated_at, updated_by }) => ({ updated_at, updated_by }))
        .concat(allScheduleLocationsByCell.map(({ updated_at, updated_by }) => ({ updated_at, updated_by })))
        .toSorted((a, b) => day(b.updated_at).diff(day(a.updated_at)))
        .at(0)
}

export const PractitionerScheduleModal = ({ selectedCells }: Props) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)

    const getLocations = useStore(selectGetLocations)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const getPractitionerScheduleLocations = useStore(selectGetPractitionerScheduleLocations)

    const firstCell = selectedCells.at(0)

    const selectedCellsIncludesHolidays = selectedCells.some(({ date }) => isHoliday(date))
    const practitionerSchedules = selectedCells.map(({ date, practitionerId }) => getPractitionerSchedules.byDateAndPractitionerId(date, practitionerId))

    const activeLocations = getLocations.byDepartment(departmentKey)
    const allScheduleStatusesByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule?.id ?? 0))
    const allScheduleLocationsByCell = practitionerSchedules.flatMap(schedule => getPractitionerScheduleLocations.byPractitionerScheduleId(schedule?.id ?? 0))
    const allPractitionerScheduleLocationsByDate = selectedCells.flatMap(({ date }) => getPractitionerScheduleLocations.byDate(date))

    const availableLocations = activeLocations.filter(
        location => !allPractitionerScheduleLocationsByDate.some(({ location_id }) => location_id === location.id)
    )
    const unavailableLocations = activeLocations.filter(location =>
        allPractitionerScheduleLocationsByDate.some(({ location_id }) => location_id === location.id)
    )

    const hasStatusOther = allScheduleStatusesByCell.some(({ status_code }) => status_code === 'OTHER')
    const latestUpdatedAtAndBy = getLatestUpdatedAtAndBy(allScheduleStatusesByCell, allScheduleLocationsByCell)

    return (
        <div className="pointer-events-none relative flex w-72 flex-col py-3">
            <Show
                condition={!selectedCellsIncludesHolidays}
                fallback={<small className="mr-9 text-gray-500">Du kan ikke foreta deg noe på en helligdag. Velg kun ordinære dager.</small>}
            >
                <div className="flex items-center justify-between px-3">
                    Aktivitet
                    <div className="pointer-events-auto">
                        {latestUpdatedAtAndBy && (
                            <p
                                className="cursor-pointer text-xs capitalize text-gray-500 hover:underline"
                                data-tooltip={`Sist endret av ${latestUpdatedAtAndBy.updated_by} ${humanizeDate(day(latestUpdatedAtAndBy.updated_at))}`}
                            >
                                {humanizeDate(day(latestUpdatedAtAndBy.updated_at))}
                            </p>
                        )}
                    </div>
                </div>

                <div className="pointer-events-auto border-b px-3 pb-3">
                    <div className="mt-3 grid grid-cols-3 gap-2">
                        {allStatuses.map((status, index) => (
                            <StatusTag
                                key={status}
                                selectedCells={selectedCells}
                                scheduleStatuses={allScheduleStatusesByCell}
                                status={status}
                                autoFocus={index === 0}
                            />
                        ))}
                    </div>
                </div>

                <ModalElement label="Ledige stuer" count={availableLocations.length} maxCount={activeLocations.length}>
                    <LocationTagsWithStaticState
                        selectedCells={selectedCells}
                        availableLocations={availableLocations}
                        scheduleLocations={allScheduleLocationsByCell}
                    />
                </ModalElement>

                <ModalElement label="Stuer med kirug" count={unavailableLocations.length} maxCount={activeLocations.length}>
                    <LocationTagsWithStaticState
                        selectedCells={selectedCells}
                        availableLocations={unavailableLocations}
                        scheduleLocations={allScheduleLocationsByCell}
                    />
                </ModalElement>

                <div className="pointer-events-auto px-3 pt-3">
                    <Show
                        condition={selectedCells.length === 1}
                        fallback={<small className="text-gray-500">Du kan ikke skrive kommentar på flere dager samtidig. Velg kun én dag.</small>}
                    >
                        <Show condition={hasStatusOther} fallback={<small className="text-gray-500">Du kan kun skrive kommentar på "Annet" aktivitet.</small>}>
                            {firstCell && <PractitionerComment cell={firstCell} />}
                        </Show>
                    </Show>
                </div>
            </Show>
        </div>
    )
}
