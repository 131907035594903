import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { DepartmentKey } from '../slices/filterSlice'
import { Practitioner, selectEntities } from './entities'

export type PractitionerId = Practitioner['id']

/*
  Groups practitioners into groups by which departments they've been assigned to.
  Also creates a group 'all', such that the key of the resulting grouping is DepartmentKey.

  exported for testing only
*/
export function _groupByDepartment(practitioners: Practitioner[]): Record<DepartmentKey, Practitioner[]> {
    const practitionerWithOrderByDepartment = {} as Record<DepartmentKey, { practitioner: Practitioner; order?: number }[]>

    for (const practitioner of practitioners) {
        for (const { order, department } of practitioner.departmentAssignments) {
            if (department) {
                ;(practitionerWithOrderByDepartment[department.id] ??= []).push({ practitioner, order: order ?? undefined })
            }
        }
    }

    const result: Record<DepartmentKey, Practitioner[]> = {
        all: sortBy(practitioners, practitioner => practitioner.short_name),
    }
    for (const [departmentId, practitioners] of Object.entries(practitionerWithOrderByDepartment)) {
        result[departmentId as DepartmentKey] = sortBy(practitioners, p => p.order).map(({ practitioner }) => practitioner)
    }
    return result
}

export const selectGetPractitioners = createSelector(selectEntities, ({ practitioners }) => {
    const result = _groupByDepartment(practitioners)
    const byId = keyBy(practitioners, 'id')

    return {
        byDepartmentKey: (departmentKey: DepartmentKey): Practitioner[] => result[departmentKey] ?? [],
        byId: (id: number): Practitioner | null => byId[id] ?? null,
        all: (): Practitioner[] => practitioners,
    }
})
