import { Spinner } from '~/components'
import { SagaStatus } from '~/hooks/booking/useGetSurgeryBookingStatus'
import { CalendarOutlined, ExclamationCircleOutlined } from '~/icons'

const statusMessages: Record<SagaStatus, string> = {
    Ongoing: 'Venter på svar fra DIPS.',
    Completed: 'Pasienten har fått tid til operasjon i Hero.',
    Failed: 'Operasjonen ble ikke bestilt.',
}

const processingColorStyles: Record<SagaStatus, string> = {
    Ongoing: ' bg-slate-100',
    Completed: 'bg-diSuccess',
    Failed: ' bg-diRed-300',
}

const processingIcons: Record<SagaStatus, JSX.Element> = {
    Ongoing: <Spinner />,
    Completed: <CalendarOutlined height={24} width={24} />,
    Failed: <ExclamationCircleOutlined height={24} width={24} />,
}

export const ProcessingIndicator = ({ status }: { status: SagaStatus }) => {
    return (
        <div className={`flex gap-2 rounded p-2 ${processingColorStyles[status]}`}>
            {processingIcons[status]}
            <p className="text-base">{statusMessages[status]}</p>
        </div>
    )
}
